<template>
    <modal :show="show" v-if=" isValid('CanAddColor') || isValid('CanEditColor') ">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel">Update  Attendance </h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel">Add  Attendance</h5>

                        </div>
                        <div>
                            <div class="card-body ">
                                <div class="row ">

                                    <div class="form-group has-label col-sm-6 ">
                                        <label class="text  font-weight-bolder"> Check In: </label>
                                        <toggle-button v-model="attendence.isCheckIn" v-on:change="GetChangeVlaue(attendence.isCheckIn,'checkIn')" class="pr-2 pl-2 pt-2" color="#3178F6" v-bind:key="randerVal" />

                                    </div>
                                    <div class="form-group has-label col-sm-6 ">
                                        <label class="text  font-weight-bolder"> On Leave: </label>
                                        <toggle-button v-model="attendence.isOnLeave" v-on:change="GetChangeVlaue(attendence.isOnLeave,'onLeave')" class="pr-2 pl-2 pt-2" color="#3178F6" v-bind:key="randerVal" />

                                    </div>
                                    <div class="form-group has-label col-sm-6 ">
                                        <label class="text  font-weight-bolder"> Absent: </label>
                                        <toggle-button v-model="attendence.isAbsent" v-on:change="GetChangeVlaue(attendence.isAbsent,'isAbsent')" class="pr-2 pl-2 pt-2" color="#3178F6" v-bind:key="randerVal" />

                                    </div>
                                    <div class="form-group has-label col-sm-12 " v-if="attendence.isPreviousAttendence">
                                        <label class="text  font-weight-bolder"> In Time : </label>
                                        <vue-timepicker v-model="attendence.checkIn" input-width="100%" />
                                    </div>
                                    <div class="form-group has-label col-sm-12 " v-if="attendence.isPreviousAttendence">
                                        <label class="text  font-weight-bolder"> Out Time : </label>
                                        <vue-timepicker v-model="attendence.checkOut" input-width="100%" />
                                    </div>
                                   

                               
                               



                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> Description: </label>
                                    <textarea class="form-control" v-model="attendence.description" type="text" />
                                </div>



                            </div>
                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" v-if="type=='Edit' ">
                                <button type="button" class="btn btn-primary  " v-on:click="Saveattendence" v-bind:disabled="$v.attendence.$invalid">Update Attendance</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">Cancel</button>
                            </div>
                            <div class="modal-footer justify-content-right" v-if="type!='Edit'">
                                <button type="button" class="btn btn-primary  " v-on:click="Saveattendence" v-bind:disabled="$v.attendence.$invalid">Save Attendance</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">Cancel</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import VueTimepicker from 'vue2-timepicker'
    import moment from 'moment';

    export default {
        props: ['show', 'attendence', 'type'],
        mixins: [clickMixin],
        components: {
            Loading,
            VueTimepicker

        },
        data: function () {
            return {
                arabic: '',
                english: '',
                render: 0,
                randerVal: 0,
                loading: false,
            }
        },
        validations: {
            attendence: {
               
            }
        },
        methods: {
            GetChangeVlaue: function (isValue, type) {
                debugger;
                if (type == 'checkIn') {
                    if (isValue) {
                        this.attendence.isOnLeave = false;
                        this.attendence.isAbsent = false;

                    }
                }
                if (type == 'onLeave') {
                    if (isValue) {
                        this.attendence.isCheckIn = false;
                        this.attendence.isAbsent = false;

                    }
                }
                if (type == 'isAbsent') {
                    if (isValue) {
                        this.attendence.isCheckIn = false;
                        this.attendence.isOnLeave = false;

                    }
                }
                this.randerVal++;
            },
            close: function () {
                this.$emit('close');
            },
          
            Saveattendence: function () {
                debugger;
                var root = this;
                this.loading = true;
                debugger;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                debugger;
                if (this.attendence.isPreviousAttendence) {
                    if (!this.attendence.isOnLeave) {
                        if (this.attendence.checkIn == '') {
                            this.$swal.fire(
                                {
                                    icon: 'error',
                                    title: 'Attendence',
                                    text: 'Check In is Required !',
                                });
                            this.loading = false;
                            return;
                        }
                    }
                    if (this.attendence.checkOut == '') {
                        this.attendence.isCheckOut = false;
                    }
                    else {
                        this.attendence.isCheckOut = true;
                        this.attendence.checkOut = moment(this.attendence.date).format('DD MMM YYYY') + ' ' + this.attendence.checkOut;

                    }
                
                    this.attendence.checkIn = moment(this.attendence.date).format('DD MMM YYYY') + ' ' + this.attendence.checkIn;


                   
                    
                }
                this.$https
                    .post('/Payroll/SaveManualAttendence', this.attendence, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal.fire({
                            icon: 'success',
                            title: 'Saved Successfully',
                            showConfirmButton: false,

                            timer: 800,
                            timerProgressBar: true,

                        });
                        root.close();
                    })
                    .catch(error => {
                        debugger;
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: error.response.data,
                                text: 'You Enter Wrong Steps',
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
           

        }
    }
</script>
