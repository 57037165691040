<template>
    <div class="row" v-if="isValid('CanViewEmployeeReg')" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-10 col-sm-12 ml-auto mr-auto" v-bind:class="$i18n.locale == 'en' ? '' : 'arabicLanguage'">
            <!--<div class="row mb-4" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title">Today Attendance</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('EmployeeRegistration.Home') }}</a></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ $t('ManualAttendance.ManualAttendance') }}</li>
                        </ol>
                    </nav>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="$i18n.locale == 'en' ? 'text-right' : 'text-left'">
                        <router-link :to="'/AttendanceReport2'"><a href="javascript:void(0)" class="btn btn-outline-primary "> Attendance Report </a></router-link>
                        <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('EmployeeRegistration.Close') }}</a></router-link>
                    </div>
                </div>

            </div>-->

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <h5 class="page_title">Today Attendance ( {{currentDate}})</h5>
                        </div>

                        <div class="col-lg-6 text-right">
                            <button type="button" class="btn btn-outline-primary " v-on:click="SaveManualAttendenceRecord(employee,'checkOutAll')">Check Out All</button>
                            <router-link :to="'/AttendanceReport'"><a href="javascript:void(0)" class="btn btn-outline-primary "> Attendance Report </a></router-link>
                            <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('EmployeeRegistration.Close') }}</a></router-link>

                        </div>


                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped table-hover table_list_bg">
                            <thead class="">
                                <tr>
                                    <th class="text-left" style="padding-top:1px !important;padding-bottom:1px !important;margin-top:0px !important;margin-bottom:0px !important;width:5%">#</th>
                                    <th class="text-center" style="padding-top:1px !important;padding-bottom:1px !important;margin-top:0px !important;margin-bottom:0px !important">Emploayee Name</th>
                                    <th class="text-center" style="padding-top:2px !important;padding-bottom:2px !important;"> Department</th>
                                    <th class="text-left" style="padding-top:2px !important;padding-bottom:2px !important;">Check In</th>
                                    <th class="text-left" style="padding-top:2px !important;padding-bottom:2px !important;">Check Out</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr v-for="(employee,index) in employeelist" v-bind:key="employee.id">

                                    <td class="text-left" style="padding-top:2px !important;padding-bottom:2px !important;width:5%">
                                        {{index+1}}
                                    </td>
                                    <td class="text-center" style="padding-top:2px !important;padding-bottom:2px !important;">

                                        <strong>
                                            <a href="javascript:void(0)" v-on:click="EmployeeAttendence(employee.employeeId)">   {{employee.englishName}}</a>
                                        </strong>

                                    </td>
                                    <td class="text-center" style="padding-top:2px !important;padding-bottom:2px !important;">
                                        {{employee.departmentEng}}

                                    </td>
                                    <td class="text-left" v-if="employee.isOnLeave" style="padding-top:2px !important;padding-bottom:2px !important;">
                                        <span style="color:red"> On Leave</span>

                                    </td>
                                    <td class="text-left" v-else-if="employee.isAbsent" style="padding-top:2px !important;padding-bottom:2px !important;">
                                        <span style="color:green">Absent</span>

                                    </td>
                                    <td class="text-left" v-else style="padding-top:2px !important;padding-bottom:2px !important;">

                                        <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkIn')" v-model="employee.isCheckIn" value="" />
                                        <span style="font-size:10px;"> &nbsp;&nbsp; {{convertDate(employee.checkIn)}}</span>


                                    </td>




                                    <td class="text-left" v-if="employee.isOnLeave" style="padding-top:2px !important;padding-bottom:2px !important;">
                                        <span style="color:red"> On Leave</span>

                                    </td>
                                    <td class="text-left" v-else-if="employee.isAbsent" style="padding-top:2px !important;padding-bottom:2px !important;">
                                        <span style="color:green">Absent</span>

                                    </td>
                                    <td class="text-left" v-else style="padding-top:2px !important;padding-bottom:2px !important;" height="10">

                                        <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkOut')" v-model="employee.isCheckOut" value="" />
                                        <span style="font-size:10px;"> &nbsp;&nbsp; {{convertDate(employee.checkOut)}}</span>

                                    </td>

                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
        <TodayAttendenceModel :attendence="manualAttendence"
                              :show="show"
                              v-if="show"
                              @close="IsSave"
                              :type="type" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import moment from 'moment';
    //import Checkbox from 'vue-material-checkbox'
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        //components: { Checkbox },
        mixins: [clickMixin],
        data: function () {
            return {
                month: '',
                value: false,
                checkbox: false,
                show: false,
                currentDate: '',
                currentMonth: '',
                nextMonth: '',
                year: '',
                arabic: '',
                english: '',
                searchQuery: '',
                employeeCheckIn: [],
                employeelist: [],
                daysList: [],
                search: '',
                manualAttendence: {
                    id: '00000000-0000-0000-0000-000000000000',
                    checkIn: '',
                    isOnLeave: false,
                    isAbsent: false,
                    isCheckIn: false,
                    isCheckOut: false,
                    isPreviousAttendence: false,
                    checkOut: '',
                    employeeId: '',
                    checkType: '',
                    description: '',
                },
            }
        },


        methods: {
            IsSave: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.employeelist = [];
                root.$https.get('Payroll/EmployeeTodayAttendence', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.employeelist = response.data;
                        root.show = false;
                    }
                });
            },
            EmployeeAttendence: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Payroll/EmployeeOverTimeQuery?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.$router.push({
                            path: '/EmployeeHourOfAttendence',
                            query: {
                                data: response.data
                            }
                        })
                    }
                });
            },
            SaveManualAttendenceRecord: function (employee, checkType) {
                debugger;

                if (checkType == 'checkIn') {

                    if (employee.attendenceId == '00000000-0000-0000-0000-000000000000') {
                        this.manualAttendence = {
                            id: employee.attendenceId,
                            checkIn: '',
                            isOnLeave: false,
                            isAbsent: false,
                            isCheckIn: true,
                            isCheckOut: false,
                            isPreviousAttendence: false,
                            checkOut: '',
                            employeeId: employee.employeeId,
                            checkType: checkType,
                            description: '',

                        }
                        this.show = !this.show;
                        this.type = "Add";
                    }
                    else {
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Attendance',
                                text: 'You cannot Change Check In Value!',
                            });
                        this.GetManualRecord();
                        return;
                    }


                }
                if (checkType == 'checkOut' || checkType == 'checkOutAll') {
                    var root = this;
                    var token = '';
                    if (this.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    if (checkType == 'checkOutAll') {
                        this.manualAttendence.checkType = checkType;
                        this.manualAttendence.id = '6bbfc474-ef95-4746-5b8f-08dab0080051';
                    }
                    else {
                        this.manualAttendence.id = employee.attendenceId;
                        this.manualAttendence.employeeId = employee.employeeId;
                        this.manualAttendence.checkType = checkType;
                        this.manualAttendence.isCheckOut = true;
                        this.manualAttendence.isCheckIn = true;
                    }

                    this.$https
                        .post('/Payroll/SaveManualAttendence', this.manualAttendence, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal.fire({
                                icon: 'success',
                                title: 'Saved Successfully',
                                showConfirmButton: false,

                                timer: 800,
                                timerProgressBar: true,

                            });
                            root.GetManualRecord();
                        })
                        .catch(error => {
                            debugger;
                            console.log(error)
                            root.$swal.fire(
                                {
                                    icon: 'error',
                                    title: error.response.data,
                                    text: 'You Enter Wrong Steps',
                                });

                            root.loading = false
                        })
                        .finally(() => root.loading = false)
                }



            },
            convertDate: function (date) {
                if (date == null)
                    return '';
                return moment(date).format('DD MMM YYYY hh:mm A');
            },

            GetManualRecord: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Payroll/EmployeeTodayAttendence', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.employeelist = response.data;
                    }
                });
            },

        },
        created: function () {
            this.$emit('input', this.$route.name);
            this.GetManualRecord();
            //this.GetEmployeeData(this.search);


        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currentDate = moment().format('ddd, DD-MMM-YYYY');
        }
    }
</script>

<style scoped>
    table {
        width: 100%;
    }

    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    tbody {
        display: block;
        overflow-y: auto;
        table-layout: fixed;
        max-height: 470px;
    }

    ::-webkit-scrollbar {
    }
</style>