<template>
    <div class="row" v-if="isValid('CanViewEmployeeReg')" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="$i18n.locale == 'en' ? '' : 'arabicLanguage'">
            <div class="row mb-4" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title">Previous And Current Attendence</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('EmployeeRegistration.Home') }}</a></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ $t('ManualAttendance.ManualAttendance') }}</li>
                        </ol>
                    </nav>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="$i18n.locale == 'en' ? 'text-right' : 'text-left'">
                        <router-link :to="'/AttendanceReport'"><a href="javascript:void(0)" class="btn btn-outline-primary "> Attendance Report </a></router-link>
                        <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('EmployeeRegistration.Close') }}</a></router-link>
                    </div>
                </div>

            </div>
            <div class="card text-center" v-if="daysList.length==0">
                <h6> First Select Week Holidays,Office Timing from Holidays Setup</h6>
                <router-link :to="'/AddHolidayOfMonth'"><a href="javascript:void(0)" class="btn btn-outline-primary ">Holidays Setup</a></router-link>
            </div>
            <div class="card" v-else>
                <div class="text-center"><h3>{{currentMonth}}--{{nextMonth}}</h3></div>
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="mt-2">
                            <div class="table-responsive">
                                <table class="table table-bordered table_list_bg">
                                    <thead class="tbl_head">
                                        <tr>
                                            <th rowspan="2">
                                                User
                                            </th>
                                            <th class="text-center" colspan="2">
                                                {{daysList[0].dayName}} <br />
                                                {{convertDate(daysList[0].weekDate)}}
                                                <br />
                                                {{daysList[0].holidayType}}

                                            </th>
                                            <th class="text-center" colspan="2">
                                                {{daysList[1].dayName}} <br />
                                                {{convertDate(daysList[1].weekDate)}}
                                                <br />
                                                {{daysList[1].holidayType}}
                                            </th>
                                            <th class="text-center" colspan="2">
                                                {{daysList[2].dayName}} <br />
                                                {{convertDate(daysList[2].weekDate)}}
                                                <br />
                                                {{daysList[2].holidayType}}
                                            </th>
                                            <th class="text-center" colspan="2">
                                                {{daysList[3].dayName}} <br />
                                                {{convertDate(daysList[3].weekDate)}}
                                                <br />
                                                {{daysList[3].holidayType}}
                                            </th>
                                            <th class="text-center" colspan="2">
                                                {{daysList[4].dayName}} <br />
                                                {{convertDate(daysList[4].weekDate)}}
                                                <br />
                                                {{daysList[4].holidayType}}
                                            </th>
                                            <th class="text-center" colspan="2">
                                                {{daysList[5].dayName}} <br />
                                                {{convertDate(daysList[5].weekDate)}}
                                                <br />
                                                {{daysList[5].holidayType}}
                                            </th>
                                            <th class="text-center" colspan="2">
                                                {{daysList[6].dayName}} <br />
                                                {{convertDate(daysList[6].weekDate)}}
                                                <br />
                                                {{daysList[6].holidayType}}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-center">
                                                In
                                            </th>
                                            <th class="text-center">
                                                Out
                                            </th>
                                            <th class="text-center">
                                                In
                                            </th>
                                            <th class="text-center">
                                                Out
                                            </th>
                                            <th class="text-center">
                                                In
                                            </th>
                                            <th class="text-center">
                                                Out
                                            </th>
                                            <th class="text-center">
                                                In
                                            </th>
                                            <th class="text-center">
                                                Out
                                            </th>
                                            <th class="text-center">
                                                In
                                            </th>
                                            <th class="text-center">
                                                Out
                                            </th>
                                            <th class="text-center">
                                                In
                                            </th>
                                            <th class="text-center">
                                                Out
                                            </th>
                                            <th class="text-center">
                                                In
                                            </th>
                                            <th class="text-center">
                                                Out
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(employee) in employeelist" v-bind:key="employee.id">
                                            <td>
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EmployeeAttendence(employee.id)">   {{employee.englishName}}</a>
                                                </strong>

                                            </td>
                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[0].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[0].isAbsent">Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkIn',employee.attendence[0].id,daysList[0].weekDate,employee.attendence[0])" v-model="employee.attendence[0].isCheckIn" v-bind:disabled="daysList[0].disable" value="" />
                                            </td>
                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[0].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[0].isAbsent"> Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkOut',employee.attendence[0].id,daysList[0].weekDate,employee.attendence[0])" v-model="employee.attendence[0].isCheckOut" v-bind:disabled="daysList[0].disable" value="" />
                                            </td>



                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[1].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[1].isAbsent"> Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkIn',employee.attendence[1].id,daysList[1].weekDate,employee.attendence[1])" v-model="employee.attendence[1].isCheckIn" v-bind:disabled="daysList[1].disable" value="" />
                                            </td>
                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[1].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[1].isAbsent"> Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkOut',employee.attendence[1].id,daysList[1].weekDate,employee.attendence[1])" v-model="employee.attendence[1].isCheckOut" v-bind:disabled="daysList[1].disable" value="" />
                                            </td>



                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[2].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[2].isAbsent"> Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkIn',employee.attendence[2].id,daysList[2].weekDate,employee.attendence[2])" v-model="employee.attendence[2].isCheckIn" v-bind:disabled="daysList[2].disable" value="" />
                                            </td>
                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[2].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[2].isAbsent"> Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkOut',employee.attendence[2].id,daysList[2].weekDate,employee.attendence[2])" v-model="employee.attendence[2].isCheckOut" v-bind:disabled="daysList[2].disable" value="" />
                                            </td>




                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[3].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[3].isAbsent"> Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkIn',employee.attendence[3].id,daysList[3].weekDate,employee.attendence[3])" v-model="employee.attendence[3].isCheckIn" v-bind:disabled="daysList[3].disable" value="" />
                                            </td>
                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[3].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[3].isAbsent"> Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkOut',employee.attendence[3].id,daysList[3].weekDate,employee.attendence[3])" v-model="employee.attendence[3].isCheckOut" v-bind:disabled="daysList[3].disable" value="" />
                                            </td>



                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[4].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[4].isAbsent"> Absent<br /></span>

                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkIn',employee.attendence[4].id,daysList[4].weekDate,employee.attendence[4])" v-model="employee.attendence[4].isCheckIn" v-bind:disabled="daysList[4].disable" value="" />
                                            </td>
                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[4].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[4].isAbsent"> Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkOut',employee.attendence[4].id,daysList[4].weekDate,employee.attendence[4])" v-model="employee.attendence[4].isCheckOut" v-bind:disabled="daysList[4].disable" value="" />
                                            </td>



                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[5].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[5].isAbsent"> Absent<br /></span>

                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkIn',employee.attendence[5].id,daysList[5].weekDate,employee.attendence[5])" v-model="employee.attendence[5].isCheckIn" v-bind:disabled="daysList[5].disable" value="" />
                                            </td>
                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[5].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[5].isAbsent"> Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkOut',employee.attendence[5].id,daysList[5].weekDate,employee.attendence[5])" v-model="employee.attendence[5].isCheckOut" v-bind:disabled="daysList[5].disable" value="" />
                                            </td>



                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[6].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[6].isAbsent"> Absent<br /></span>

                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkIn',employee.attendence[6].id,daysList[6].weekDate,employee.attendence[6])" v-model="employee.attendence[6].isCheckIn" v-bind:disabled="daysList[6].disable" value="" />
                                            </td>
                                            <td class="text-center">
                                                <span style="color:red;font-size:8px" v-if="employee.attendence[6].isOnLeave"> On Leave<br /></span>
                                                <span style="color:green;font-size:8px" v-else-if="employee.attendence[6].isAbsent"> Absent<br /></span>
                                                <input type="checkbox" v-on:input="SaveManualAttendenceRecord(employee,'checkOut',employee.attendence[6].id,daysList[6].weekDate,employee.attendence[6])" v-model="employee.attendence[6].isCheckOut" v-bind:disabled="daysList[6].disable" value="" />
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TodayAttendenceModel :attendence="manualAttendence"
                                  :show="show"
                                  v-if="show"
                                  @close="IsSave"
                                  :type="type" />
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import moment from 'moment';
    //import Checkbox from 'vue-material-checkbox'
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        //components: { Checkbox },
        mixins: [clickMixin],
        data: function () {
            return {
                month: '',
                value: false,
                checkbox: false,
                currentMonth: '',
                nextMonth: '',
                year: '',
                arabic: '',
                english: '',
                searchQuery: '',
                employeeCheckIn: [],
                employeelist: [],
                daysList: [],
                search: '',
                show: false,

                manualAttendence: {
                    id: '00000000-0000-0000-0000-000000000000',
                    checkIn: '',
                    date: '',
                    isOnLeave: false,
                    isPreviousAttendence: true,
                    isCheckIn: false,
                    isCheckOut: false,
                    checkOut: '',
                    employeeId: '',
                    checkType: '',
                    description: '',
                },
            }
        },


        methods: {
            IsSave: function () {
                this.GetManualRecord();
            },
            EmployeeAttendence: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Payroll/EmployeeOverTimeQuery?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.$router.push({
                            path: '/EmployeeHourOfAttendence',
                            query: {
                                data: response.data
                            }
                        })
                    }
                });
            },
            SaveManualAttendenceRecord: function (employee, checkType, id, weekDate,attendence) {
                debugger;
                this.loading = true;

                if (checkType == 'checkIn') {

                    if (id == '00000000-0000-0000-0000-000000000000') {
                        this.manualAttendence = {
                            id: id,
                            checkIn: '',
                            isOnLeave: false,
                            isAbsent: false,
                            isCheckIn: true,
                            isCheckOut: true,
                            isPreviousAttendence: true,
                            checkOut: '',
                            date: weekDate,
                            employeeId: employee.id,
                            checkType: checkType,
                            description: '',

                        }
                        this.show = !this.show;
                        this.type = "Add";
                    }
                    else {
                        this.manualAttendence.id = id;
                        this.manualAttendence.employeeId = employee.id;
                        this.manualAttendence.checkType = 'Not Check';
                        this.manualAttendence.checkIn = attendence.checkIn;
                        this.manualAttendence.date = attendence.date;
                        this.manualAttendence.checkOut = attendence.checkOut;
                        this.manualAttendence.checkOut = attendence.checkOut;
                        this.manualAttendence.isCheckIn = attendence.isCheckIn;
                        this.manualAttendence.isCheckOut = attendence.isCheckOut;
                        this.manualAttendence.isAbsent = attendence.isAbsent;
                        this.manualAttendence.isOnLeave = attendence.isOnLeave;
                       
                        this.show = !this.show;
                        this.type = "Edit";
                    }



                }
                if (checkType == 'checkOut') {
                    if (id == '00000000-0000-0000-0000-000000000000') {
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Attendence',
                                text: 'First Click on Check In !',
                            });
                        this.GetManualRecord();

                        return;

                    }
                    else {
                        this.manualAttendence.id = id;
                        this.manualAttendence.employeeId = employee.id;
                        this.manualAttendence.checkType = checkType;
                        this.manualAttendence.id = id;
                        this.manualAttendence.employeeId = employee.id;
                        this.manualAttendence.checkType = 'Not Check';
                        this.manualAttendence.checkIn = attendence.checkIn;
                        this.manualAttendence.isCheckIn = attendence.isCheckIn;
                        this.manualAttendence.isCheckOut = attendence.isCheckOut;
                        this.manualAttendence.isOnLeave = attendence.isOnLeave;
                        this.manualAttendence.isAbsent = attendence.isAbsent;
                        this.manualAttendence.date = attendence.date;
                        if (this.manualAttendence.isCheckOut) {
                            this.manualAttendence.checkOut = attendence.checkOut;
                        }
                        else {
                            this.manualAttendence.checkOut = '';
                        }
                        this.show = !this.show;
                        this.type = "Edit";
                    }

                }

                

                //var root = this;
                //var token = '';
                //if (this.$session.exists()) {
                //    token = localStorage.getItem('token');
                //}
                //this.manualAttendence.id = id;
                //this.manualAttendence.employeeId = employee.id;
                //this.manualAttendence.checkType = checkType;
                //if (checkType == 'checkIn') {
                //    this.manualAttendence.isCheckIn = true;
                //}
                //if (checkType == 'checkOut') {
                //    this.manualAttendence.isCheckOut = true;
                //}
                //this.$https
                //    .post('/Payroll/SaveManualAttendence', this.manualAttendence, { headers: { "Authorization": `Bearer ${token}` } })
                //    .then(response => {
                //        root.loading = false
                //        root.info = response.data.bpi

                //        root.$swal.fire({
                //            icon: 'success',
                //            title: 'Saved Successfully',
                //            showConfirmButton: false,

                //            timer: 800,
                //            timerProgressBar: true,

                //        });
                //        root.GetManualRecord();
                //    })
                //    .catch(error => {
                //        debugger;
                //        console.log(error)
                //        root.$swal.fire(
                //            {
                //                icon: 'error',
                //                title: error.response.data,
                //                text: 'You Enter Wrong Steps',
                //            });

                //        root.loading = false
                //    })
                //    .finally(() => root.loading = false)
            },
            convertDate: function (date) {
                return moment(date).format('DD MMM YYYY');
            },

            GetManualRecord: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.show = false;
                root.$https.get('Payroll/ManualList', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.month = response.data.month;
                        root.daysList = response.data.dayOfWeekLookUpModel;
                        root.employeelist = response.data.employeeManualAttendence;
                        root.employeeCheckIn = response.data.employeeCheckIn;

                    }
                });
            },

        },
        created: function () {
            this.$emit('input', this.$route.name);
            this.GetManualRecord();
            //this.GetEmployeeData(this.search);


        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currentMonth = moment().format('MMM')
            this.nextMonth = moment().add(1, 'M').format("MMM");
        }
    }
</script>

<style scoped>
    .tbl_head tr th {
        padding-bottom: 0;
    }
</style>