<template>
    <div class="col-md-8 ml-auto mr-auto" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
        <div class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="row p-0 DayHeading ">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <span v-if="holidays.id=='00000000-0000-0000-0000-000000000000'"> Holidays Setup</span>
                                <span v-else>Holidays Setup</span>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="$i18n.locale == 'en' ? 'text-right' : 'text-left'" style="margin-top:18px;">
                                <!--<a href="javascript:void(0)" class="btn btn-primary btn-sm" v-on:click="openmodel"><i class="fa fa-plus"></i>Add Cheque Book</a>-->

                            </div>

                        </div>
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label>In Time :<span class="LabelColour">*</span></label>
                                    <!--<VueCtkDateTimePicker formatted="h:mm a"
                                                          :only-time="true"
                                                          format="HH:mm"
                                                          label="Choose time"
                                                          time-format="h:mm a"
                                                          v-model="inTime"></VueCtkDateTimePicker>-->
                                    <vue-timepicker v-model="holidays.inTime"  input-width="100%" />

                                </div>
                                <div class="col-sm-4">
                                    <label>Out Time :<span class="LabelColour">*</span></label>
                                    <!--<VueCtkDateTimePicker formatted="h:mm a"
                                                          :only-time="true"
                                                          format="HH:mm"
                                                          label="Choose time"
                                                          time-format="h:mm a"
                                                          v-model="outTime"></VueCtkDateTimePicker>-->
                                    <vue-timepicker v-model="holidays.outTime" input-width="100%" />

                                </div>
                                <div class="col-sm-4">
                                    <label>Week Holidays :<span class="LabelColour"> *</span></label>
                                    <multiselect v-model="processContractors" @input="contractorListId(processContractors)" :options="options" :multiple="true" placeholder="Select Days" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:class="$i18n.locale == 'en' ? 'text-left ' : 'arabicLanguage '">
                                    </multiselect>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 pt-4">
                                    <label>Guested Holidays :<span class="LabelColour"> *</span></label>
                                    <div class="table-responsive  " v-bind:class="{ 'horizontal-table': tableLength >= cardLength }">
                                        <table class="table table-bordered" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                                            <thead class="tableHeaderColor">
                                                <tr class="text-capitalize text-center">
                                                    <th style="width: 5%;">
                                                        #
                                                    </th>
                                                    <th style="width:60%;">Description</th>
                                                    <th style="width:25%;">Date</th>
                                                    <th style="width:10%;">Action</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(holiday,index) in holidays.guestedHolidays" v-bind:key="holiday.id" style="background:#EAF1FE;">
                                                    <td>{{index+1}}</td>
                                                    <td>
                                                        <input class="textareaauto" v-model="holiday.description" style="background-color:transparent" v-bind:placeholder="$t('AddLineItem.WriteHere')" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'" />
                                                    </td>


                                                    <td>
                                                        <datepicker v-model="holiday.date"></datepicker>
                                                    </td>
                                                    <td class="text-center" v-if="index == holidays.guestedHolidays.length - 1 && addItem == false">
                                                        <button title="Add New Item" class="btn btn-sm btn-danger btn-icon btn-round" v-on:click="addDailyExpense">
                                                            <i class="fa fa-plus"></i>
                                                        </button>
                                                    </td>
                                                    <td class="text-center" v-else>
                                                        <button title="Remove Item" class="btn btn-secondary btn-neutral btn-sm btn-round  btn-icon" v-on:click="removeExpense(holiday.id)">
                                                            <i class="nc-icon nc-simple-remove"></i>
                                                        </button>
                                                    </td>



                                                </tr>
                                                <tr v-if="addItem" style="background:#EAF1FE;">
                                                    <td class="text-center">{{holidays.guestedHolidays.length+1}} </td>
                                                    <td>
                                                        <input class="textareaauto" v-model="holidayItem.description" v-bind:placeholder="$t('WriteHere')" style="width:100% !important;background-color:#ffffff;height:40px;" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'" />
                                                    </td>
                                                    <td>
                                                        <datepicker v-model="holidayItem.date" :key="refresh"></datepicker>
                                                    </td>


                                                    <td class="text-center" v-if="holidayItem.date==''  ">
                                                        <button title="Add New Item" class="btn btn-sm  btn-icon btn-danger btn-round" disabled v-on:click="addDailyExpense">
                                                            <i class="fa fa-plus"></i>
                                                        </button>
                                                    </td>
                                                    <td class="text-center" v-else>
                                                        <button title="Add New Item" class="btn btn-sm  btn-icon btn-danger btn-round" v-on:click="addDailyExpense">
                                                            <i class="fa fa-plus"></i>
                                                        </button>
                                                    </td>


                                                </tr>

                                            </tbody>

                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-md-12">
                        <button class="btn btn-danger  float-right  mr-2" v-on:click="GoToHolidays">Cancel</button>
                        <button class="btn btn-primary float-right   mr-2" v-bind:disabled="$v.holidays.$invalid" v-if="holidays.id=='00000000-0000-0000-0000-000000000000'  " v-on:click="SaveHolidays"><i class="far fa-save"></i> Save</button>
                        <button class="btn btn-primary float-right    mr-2" v-bind:disabled="$v.holidays.$invalid" v-if="holidays.id!='00000000-0000-0000-0000-000000000000'  " v-on:click="SaveHolidays"><i class="far fa-save"></i> Update</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!--<div v-else> <acessdenied></acessdenied></div>-->

</template>
<script>
    //import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
    //import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
    //import Vue from 'vue'
    //Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
    import VueTimepicker from 'vue2-timepicker'
    import Multiselect from 'vue-multiselect'
    import clickMixin from '@/Mixins/clickMixin'
    import moment from 'moment'

    //import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
    export default ({

        mixins: [clickMixin],
        components: {
            Multiselect,
            VueTimepicker

        },
        data: function () {
            return {
                arabic: '',
                english: '',
                show: false,
                addItem: false,
                weekDays: '',
                inTime: '',
                outTime: '',
                options: [],
                processContractors: [],


                active: 'personal',
                rendered: 0,
                holidays: {
                    id: '00000000-0000-0000-0000-000000000000',
                    inTime: '',
                    outTime: '',
                    weekHolidays: [],
                    guestedHolidays: [],
                },
                holidayItem: {
                    id: '',
                    description: '',
                    date: '',
                },
                loading: false,
                tableLength: 0,
                cardLength: 0,
                refresh: 0,
                randerTime: 0,
            }
        },
        validations: {
            holidays:
            {
                code: {

                },


            }
        },

        methods: {
            contractorListId: function (value) {
                var root = this;
                root.holidays.weekHolidays = value;

            },
            getData: function (x, value) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Payroll/WeekDaysList', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        debugger;
                        response.data.results.forEach(function (result) {
                            root.options.push({
                                id: result.id,
                                name: result.name,
                                nameArabic: result.nameArabic,
                            })
                            if (x) {
                                debugger;
                                if (value != undefined) {
                                    value.forEach(function (ids) {

                                        if (ids.id == result.id) {

                                            root.processContractors.push({
                                                id: result.id,
                                                name: result.name,
                                                nameArabic: result.nameArabic,
                                            });
                                            root.contractorListId(root.processContractors);
                                        }
                                    })

                                }
                            }

                        })
                    }


                    //if (response.data != null) {
                    //    response.data.results.forEach(function (cat) {


                    //        root.options.push({
                    //            id: cat.id,
                    //            name: cat.name,
                    //            nameArabic: cat.nameArabic,
                    //        })


                    //    })
                    //}
                });
            },

            addDailyExpense: function () {

                this.loading = true;
                this.holidays.guestedHolidays.push({
                    id: this.createUUID(),
                    date: this.holidayItem.date,
                    description: this.holidayItem.description
                });

                this.holidayItem = {
                    id: '',
                    date: '',
                    description: '',
                };
                this.refresh += 1;
            },
            createUUID: function () {
                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },

            removeExpense: function (id) {
                debugger;
                var ds = this.holidays.guestedHolidays.findIndex(function (i) {
                    return i.id === id;
                });

                this.holidays.guestedHolidays.splice(ds, 1);
            },
            GoToHolidays: function () {
                this.$router.push('/ManualAttendance');
            },
            GetHolidays: function () {
                var root = this;
                var id = '';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }



                debugger
                root.$https.get('/Payroll/HolidaysDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {


                    debugger;


                    if (response.data != null && response.data != '') {

                        root.holidays = response.data;

                        //root.inTime = moment(root.holidays.inTime).format('llll');
                        //root.outTime = moment(root.holidays.outTime).format('llll');
                        root.getData(true, root.holidays.weekHolidays);

                    }
                    else {
                        root.getData(false);

                    }


                });

            },
            SaveHolidays: function () {

                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                //this.holidays.inTime = this.inTime;
                //this.holidays.outTime = this.outTime;
                this.$https
                    .post('/Payroll/SaveHolidays', this.holidays, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.loading = false
                        root.info = response.data.bpi

                        root.$swal.fire({
                            icon: 'success',
                            title: 'Saved Successfully',
                            showConfirmButton: false,

                            timer: 800,
                            timerProgressBar: true,

                        });

                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },

        },
        created: function () {

            this.$emit('input', this.$route.name);

            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');

            //if (this.$route.query.data == undefined) {
            //    console.log("Best of luck ");
            //}
            //if (this.$route.query.data != undefined) {

            //    this.holidays = this.$route.query.data;

            //}
        },
        mounted: function () {
            this.holidays.inTime= moment().format('llll');
            this.holidays.outTime= moment().format('llll');
            this.GetHolidays();

            this.addItem = this.holidays.guestedHolidays.length > 0 ? false : true;

        }
    })

</script>